@use "uswds-core" as *;

// Maps for High Contrast Mode

$icon-map: (
  color: ButtonText,
  svg-height: 20,
  svg-width: 20,
  height: units(2),
);

$chevron-open: map-merge(
  $icon-map,
  (
    "name": "expand_more",
  )
);

$close: map-merge(
  $icon-map,
  (
    "name": "close",
  )
);

.usa-combo-box {
  max-width: units($theme-input-max-width);
  position: relative;
}

.usa-combo-box--pristine {
  .usa-combo-box__input {
    padding-right: calc(5em + 4px);

    &::-ms-clear {
      display: none;
    }
  }

  .usa-combo-box__clear-input {
    display: block;

    @media (forced-colors: active) {
      @include add-color-icon($close);
      height: 1.5rem;
      width: auto;
      top: 0.5rem;
    }
  }
}

.usa-combo-box__input {
  @extend %block-input-general;
  @extend %block-input-styles;
  appearance: none;
  margin-bottom: 0;
  max-width: none;
  padding-right: calc(2.5em + 3px);

  &:disabled,
  &[aria-disabled="true"] {
    @include u-disabled;
    @include u-disabled-high-contrast-border;

    // Fix for Safari
    -webkit-text-fill-color: color("disabled-dark");

    // Placeholder overrides to ensure color contrast compliance accross browsers
    &::placeholder {
      opacity: 1;
      color: color("disabled-dark");
    }

    & ~ .usa-combo-box__input-button-separator {
      background-color: color("disabled-dark");
      cursor: not-allowed;
    }
  }
}

button.usa-combo-box__toggle-list,
button.usa-combo-box__clear-input {
  &:focus {
    outline-offset: -4px;
  }

  &:disabled,
  &[aria-disabled="true"] {
    cursor: not-allowed;
  }
}

/* stylelint-disable selector-class-pattern */
.usa-combo-box__toggle-list__wrapper:focus,
.usa-combo-box__clear-input__wrapper:focus {
  outline: 0;
}
/* stylelint-enable selector-class-pattern */

.usa-combo-box__toggle-list,
.usa-combo-box__clear-input {
  background-color: transparent;
  background-position: center;
  background-size: auto units(3);
  border: 0;
  bottom: 1px;
  cursor: pointer;
  margin-bottom: 0;
  opacity: 0.6;
  padding-right: units(4);
  position: absolute;
  top: 1px;
  z-index: z-index(100);
}

.usa-combo-box__clear-input {
  @include add-background-svg("usa-icons/close");
  display: none;
  right: calc(2.5em + 3px);
}

.usa-combo-box__toggle-list {
  @include add-background-svg("usa-icons/expand_more");
  background-size: auto units(4);
  right: 1px;

  @media (forced-colors: active) {
    @include add-color-icon($chevron-open);
    height: auto;
    width: auto;

    &:disabled,
    &[aria-disabled="true"] {
      background-color: color(GrayText);
    }
  }
}

.usa-combo-box__input-button-separator {
  background-color: color("gray-cool-20");
  position: absolute;
  top: 1px;
  height: calc(100% - #{units(2)});
  margin-bottom: units(1);
  margin-top: units(1);
  width: 1px;
  right: calc(2.5em + 2px);
  box-sizing: border-box;
  z-index: z-index(200);

  @media (forced-colors: active) {
    background-color: ButtonText;
  }
}

.usa-combo-box__list {
  @extend %block-input-general;
  @include u-border(1px, "base-dark");
  background-color: color("white");
  border-radius: 0;
  border-top: 0;
  margin: 0;
  max-height: 12.1em;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: z-index(300);

  &:focus {
    outline: 0;
  }
}

.usa-combo-box__list-option {
  border-bottom: units(1px) solid color("base-lighter");
  cursor: pointer;
  display: block;
  padding: units(1);

  &--focused {
    @include focus-outline($width: 2px, $offset: -2px, $color: "blue-warm-80v");
    position: relative;
    z-index: z-index(100);

    &:focus {
      outline-offset: -4px;
    }
  }

  &--selected {
    background-color: color("primary");
    border-color: color("primary");
    color: color("white");
  }
}

.usa-combo-box__list-option--no-results {
  cursor: not-allowed;
  display: block;
  padding: units(1);
}
