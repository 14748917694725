@use "../../functions/utilities/color" as *;
@use "../../settings" as *;
@use "../../variables/border-high-contrast" as *;
// Overrides background-color and cursor styles of elements to display as disabled

@mixin u-disabled($bg-color: "disabled-lighter", $text-color: "disabled-dark") {
  background-color: color($bg-color);
  color: color($text-color);
  cursor: not-allowed;
  opacity: 1; // Override disabled default opacity shift (Necessary for Chromium, iOS Safari)

  &:hover,
  &:active,
  &:focus,
  &.usa-focus {
    background-color: color($bg-color);
    color: color($text-color);
  }

  @media (forced-colors: active) {
    border: 0;
    color: color(GrayText);

    &:hover,
    &:active,
    &:focus,
    &.usa-focus {
      color: color(GrayText);
    }
  }
}

@mixin u-disabled-high-contrast-border {
  @media (forced-colors: active) {
    border: $border-high-contrast-disabled;
  }
}
